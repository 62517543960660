import { defineStore } from 'pinia'
import { Plans } from '@/modules/requests/plans';
import { ref, Ref } from 'vue';

export interface PlanType {
    _id?: string;
    name: string;
    nb_workspaces: number;
    nb_filtres: number;
    nb_comptes: number;
    access_msg: string;
    access_autosecure: Boolean;
    access_autosecure_plus: Boolean;
    stripe_plan_id: String;
}

export interface Planstype {
    plans: PlanType[];
}


export const usePlansStore = defineStore('plans', () => {
    const plan: Ref<PlanType | null> = ref(null);
    const plans: Ref<Planstype | null> = ref(null);

    async function getPlan(data: object) {
        plan.value = await Plans.getPlan(data);
    }

    async function getAllPlans() {
        plans.value = await Plans.getAllPlans();
    }

    return { plan, plans, getPlan, getAllPlans }
})