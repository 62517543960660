import axios, { AxiosResponse, AxiosError } from 'axios';
import { handleSuccess, handleError } from './statusHandler';

declare module 'axios' {
    export interface AxiosRequestConfig {
        DISPLAY_RETURN?: boolean;
    }
}

axios.interceptors.response.use(
    function (response: AxiosResponse<any>): AxiosResponse<any> {
        if (response.config.DISPLAY_RETURN) {
            handleSuccess(response);
        }
        return response;
    },
    function (error: AxiosError<any>): Promise<AxiosError<any>> {
        if (error.response && error.response.config.DISPLAY_RETURN) {
            handleError(error);
        }
        return Promise.reject(error);
    }
);


export class Requester {
    private base: string;
    private url: string;
    private token: string | null;
    private displayReturn: boolean;
    private sendWorkspaceId: boolean;

    constructor(url: string, displayReturn: boolean = true, sendWorkspaceId: boolean = false) {
        this.base = import.meta.env.VITE_BACK_BASE_URL as string;
        this.url = url;
        this.token = localStorage.getItem('token');
        this.displayReturn = displayReturn;
        this.sendWorkspaceId = sendWorkspaceId
    }

    private async request(method: string, data: { [key: string]: any }, params: string | string[][]): Promise<any> {
        const payload = new URLSearchParams(params);
        const config = {
            method: method,
            baseURL: this.base,
            url: this.url,
            credentials: "include",
            withCredentials: true,
            mode: "cors",
            headers: {
                'x-xsrf-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'withCredentials': true, 
            },
            data: data,
            DISPLAY_RETURN: this.displayReturn
        };
    
        if (payload.toString()) { config.url += `?${payload}`; }
    
        try {
            const response = await axios(config);
            if (method === 'get') {
                return response.data;
            }
            return response;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    return error.response;
                } else {
                    throw new Error("Network error or no response received");
                }
            }
            throw error;
        }
    } 

    public async get(params: string = ""): Promise<any> {
        return this.request('get', {}, params);
    }

    public async post(data: object = {}, params: string = ""): Promise<any> {
        return this.request('post', data, params);
    }

    public async patch(data: object = {}, params: string = ""): Promise<any> {
        return this.request('patch', data, params);
    }

    public async delete(data: object = {}, params: string = ""): Promise<any> {
        return this.request('delete', data, params);
    }
}
