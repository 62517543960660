/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'

// Icons
import { OhVueIcon, addIcons } from "oh-vue-icons";
import { 
  BiSunFill, 
  BiMoonFill, 
  MdSpacedashboardRound, 
  FaBan, 
  BiPlus, 
  MdDashboardRound, 
  BiTriangleFill, 
  BiChevronCompactLeft, 
  MdHomeRound, 
  FaShoppingBasket, 
  MdReviews,
  HiSolidMail,
  RiLockPasswordFill,
  FaUserAlt,
  MdAccountcircle,
  BiTelephoneFill,
  FaMapMarkedAlt,
  HiSolidOfficeBuilding,
  BiEyeFill,
  BiEyeSlashFill,
  MdGavel,
  MdManageaccounts,
  IoGameController,
  FaServer,
  MdBoltSharp,
  HiSolidCode,
  FaCog,
  MdLogout,
  HiSolidPlus,
  SiDiscord,
  GiWorld,
  LaLinkedinIn,
  BiCheckLg, 
  MdClose, 
  BiArrowDown, 
  BiArrowUp,
  BiArrowLeft,
  BiArrowRight,
  FaFileInvoice,
  MdMenu,
  MdCheckbox,
  BiChevronDown,
  BiChevronUp,
  BiChevronLeft,
  BiChevronRight,
  BiChevronBarLeft,
  BiChevronBarRight,
  MdCheckboxoutlineblank,
  BiTrashFill,
  BiBook,
  BiDownload,
  FaCrown,
  BiLightningChargeFill,
  HiMinus,
  HiSolidPencilAlt,
  FaQuoteLeft,
  FaQuoteRight,
  HiSolidQuestionMarkCircle,
  OiGrabber,
  BiInboxFill,
  MdDrivefolderuploadRound,
  MdDashboardcustomizeRound,
  MdSettings,
  IoStatsChart,
  MdMessageRound,
  IoNotifications,
  HiMenu,
  FaSearch,
  BiStar,
  BiStarHalf,
  BiStarFill,
  FaArrowUp,
  BiDot,
  FaEdit,
  MdAccountbalancewallet,
  MdSupportagent,
  IoSettingsSharp,
  HiSolidDocumentSearch,
  MdPassword,
  MdAddaphotoOutlined,
} from "oh-vue-icons/icons";

addIcons(
  MdSupportagent,
  MdPassword,
  HiSolidDocumentSearch,
  IoSettingsSharp,
  BiSunFill, 
  BiMoonFill, 
  MdSpacedashboardRound, 
  FaBan, 
  BiPlus, 
  MdDashboardRound, 
  BiTriangleFill, 
  BiChevronCompactLeft, 
  MdHomeRound, 
  FaShoppingBasket, 
  MdReviews,
  HiSolidMail,
  RiLockPasswordFill,
  FaUserAlt,
  MdAccountcircle,
  BiTelephoneFill,
  FaMapMarkedAlt,
  HiSolidOfficeBuilding,
  BiEyeFill,
  BiEyeSlashFill,
  MdGavel,
  MdManageaccounts,
  IoGameController,
  FaServer,
  MdBoltSharp,
  HiSolidCode,
  FaCog,
  MdLogout,
  HiSolidPlus,
  SiDiscord,
  GiWorld,
  LaLinkedinIn,
  BiCheckLg, 
  MdClose, 
  BiArrowDown, 
  BiArrowUp,
  BiArrowLeft,
  BiArrowRight,
  FaFileInvoice,
  MdMenu,
  MdCheckbox,
  BiChevronDown,
  BiChevronUp,
  BiChevronLeft,
  BiChevronRight,
  BiChevronBarLeft,
  BiChevronBarRight,
  MdCheckboxoutlineblank,
  BiTrashFill,
  BiBook,
  BiDownload,
  FaCrown,
  BiLightningChargeFill,
  HiMinus,
  HiSolidPencilAlt,
  FaQuoteLeft,
  FaQuoteRight,
  HiSolidQuestionMarkCircle,
  OiGrabber,
  BiInboxFill,
  MdDrivefolderuploadRound,
  MdDashboardcustomizeRound,
  MdSettings,
  IoStatsChart,
  MdMessageRound,
  IoNotifications,
  HiMenu,
  FaSearch,
  BiStarHalf,
  BiStarFill,
  BiStar,
  FaArrowUp,
  BiDot,
  FaEdit,
  MdAccountbalancewallet,
  MdAddaphotoOutlined,
  {
    name: 'twitter-x',
    width: 300,
    height: 271,
    d: 'm236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z'
  }
);

// Components
import App from './App.vue'
import VueTour from "v3-tour";
import { createPinia } from 'pinia';

import axios from 'axios';

import '@/scss/style.scss';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import VueApexCharts from 'vue3-apexcharts';
import VueTablerIcons from 'vue-tabler-icons';

axios.defaults.baseURL = import.meta.env.VITE_FRONT_BASE_URL || 'https://takefast.fr';

// Composables
import { createApp } from 'vue'

declare global {
  interface Window {
    tours: any;
  }
}

const app = createApp(App)

registerPlugins(app)

app.use(VueTour);

app.use(PerfectScrollbarPlugin);
app.use(VueTablerIcons);
app.use(VueApexCharts);

app.component("oh-vue-icon", OhVueIcon);
app.mount('#app')
