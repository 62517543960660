import { defineStore } from 'pinia'
import { ref, Ref } from 'vue';
import { Users } from '@/modules/requests/users';
import { Base64 } from 'base64-string';

interface UserType {
    _id: string;
    username: string;
    email: string;
    plan?: string;
    icon: string;
    customer_id_stripe?: string;
    accept_tnc?: boolean;
    subscribe_newsletter?: boolean;
    createdAt?: Date;
    email_verified?: boolean;
    email_verification_token?: string;
    settings?: Record<string, any>;
}


export const username: Ref<string> = ref(localStorage.getItem('username') || '')

export const useUserStore = defineStore('user', () => {
    const user: Ref<UserType | null> = ref(null);

    async function getUser() {
        user.value = await Users.getUser();

        if (user.value) {
            const enc = new Base64();
            user.value.icon = enc.decode(user.value.icon);
        }
    }

    async function updateUser(data: Object) {
        const response = await Users.updateUser(data);
        user.value = response.data.updated_user;
        username.value = response.data.updated_user.username;
        localStorage.setItem('username', username.value)
    }

    async function updateUserPassword(oldPassword: string, password: string) {
        await Users.updateUserPassword(oldPassword, password);
    }

    async function deleteUser(userId: string) {
        await Users.deleteUser(userId);
    }

    async function logoutUser() {
        await Users.logoutUser();
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('level');
        localStorage.removeItem('username');
        username.value = '';
    }

    async function sendCode() {
        await Users.sendCode();
    }

    return { user, getUser, updateUser, updateUserPassword, deleteUser, logoutUser, sendCode }
})