import { Requester } from '../requester';

export class Plans {

    static async getPlan(data: Object) {
        const planRequester = new Requester('/plans', true, true);
        const temp: any = data;
        const params = new URLSearchParams(temp).toString();
        
        return await planRequester.get(params)
    }

    static async getAllPlans() {
        const planRequester = new Requester('/plans/all', true, true);
        return await planRequester.get();
    }
}

