/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify'
import "@/scss/style.scss";  
import pinia from '../store/index'
import router from '../router'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { VueMasonryPlugin } from "vue-masonry";

// Types
import type { App } from 'vue'
export function registerPlugins (app: App) {
  app
    .use(vuetify)
    .use(pinia)
    .use(router)
    .use(VueMasonryPlugin)
    .component('VueDatePicker', VueDatePicker)
}
