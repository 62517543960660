import loadingStore from '../store/loading';
import { RouteLocationNormalized } from 'vue-router';
import { ref } from 'vue';

export const authoriseRunning = ref(false);
export const pageBeforeDisable = ref('dashboard');

const userId = localStorage.getItem('userId');

export async function authorize(to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) {
  loadingStore.startLoading();
  authoriseRunning.value = true;

  loadingStore.stopLoading();
  return next();
}
