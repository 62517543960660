/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
// import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
// Composables
import { createVuetify, type ThemeDefinition } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

//Custom themes
const darkCustom: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#FFFFFF',
    surface: '#54BD95',
    primary: '#191A15',
    'primary-darken-1': '#C7CAC0',
    secondary: '#54BD95',
    'secondary-darken-1': '#54BD95',
    error: '#B20100',
    info: '#2196F3',
    success: '#6DD130',
    warning: '#FB8C00',
    'on-background': '#FCFFF3',
    footer: '#00213D',
    white: '#FCFFF3',
    black: '#010221',
    grey: '#B3B6BF',
    main: '#54BD95',
    'main-color-light': '#6BC2A1',
    'main-grand-light': '#85DAB9',
    subcolor: '#52BDAA',
    'subcolor-hover': '#52BDAA20',
    'background-white-dark': '#F9F8FE',
    'background-white': '#F9FAFB',
    'background-white-hover': '#F9FAFB',
    lumiere: '#FFD6D6',
    light: '#A6A6A6',
    active: '#000000',
    'background-dark': '#161C28',
    'dark-text': '#191A15',
    'background-dashboard': '#F2F2F7',
    'section-color': '#54BE96',
    containerBg: '#f8f9fa',
    inputBorder: '#bec8d0',
    borderLight: '#e8ebee',
    lightText: '#5b6b79',
    yellow: '#FBBB00',
  },
}

const lightCustom: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#54BD95',
    primary: '#191A15',
    'primary-darken-1': '#C7CAC0',
    secondary: '#54BD95',
    'secondary-darken-1': '#54BD95',
    error: '#B20100',
    info: '#2196F3',
    success: '#6DD130',
    warning: '#FB8C00',
    'on-background': '#FCFFF3',
    footer: '#00213D',
    white: '#FCFFF3',
    black: '#010221',
    grey: '#B3B6BF',
    main: '#54BD95',
    'main-color-light': '#6BC2A1',
    'main-grand-light': '#85DAB9',
    subcolor: '#52BDAA',
    'subcolor-hover': '#52BDAA20',
    'background-white-dark': '#F9F8FE',
    'background-white': '#F9FAFB',
    'background-white-hover': '#F9FAFB',
    lumiere: '#FFD6D6',
    light: '#A6A6A6',
    active: '#000000',
    'background-dark': '#161C28',
    'dark-text': '#191A15',
    'background-dashboard': '#F2F2F7',
    'section-color': '#54BE96',
    containerBg: '#f8f9fa',
    inputBorder: '#bec8d0',
    borderLight: '#e8ebee',
    lightText: '#5b6b79',
    yellow: '#FBBB00',
  },
}

export default createVuetify({
  theme: {
    defaultTheme: localStorage.theme || 'darkCustom',
    themes: {
      darkCustom,
      lightCustom
    },
  },
})
